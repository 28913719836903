<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col cols="12" sm="6" md="8">
        <div>
          <h1>EVALUATION SURVEY</h1>
        </div>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationDetail',
                params: { id: $route.params.id },
              })
            "
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <customer-info />
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          label="How long do you plan to be in the property?"
          v-model="survey_info.property_plan"
          :items="property_plan_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Use for the repair area"
          v-model="survey_info.area_repaired"
          :items="area_repaired_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Other Bids"
          v-model="survey_info.other_bids"
          :items="other_bids_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Timeline to Fix"
          v-model="survey_info.timeline_fix"
          :items="timeline_fix_values"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-textarea
          label="Other Comments"
          auto-grow
          outlined
          rows="3"
          row-height="40"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="12" sm="6">
            <label>Evidence of Water Intrusion?</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Mold / Effervescence</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Wall Bow</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Wall Lean</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Foundation Wall Cracks</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Insufficient Sump Pump</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Garage Floor Cracks</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Poor Exterior Drainage</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Damage to Finishings</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Will Get Worse in the Future</label>
          </v-col>
          <v-col cols="12" sm="6">
            <v-rating
              background-color="indigo lighten-3"
              color="indigo"
              large
            ></v-rating>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
export default {
  name: "EnterBid.vue",
  components: { CustomerInfo },
  data() {
    return {
      evaluation_id: this.$route.params.id,
      property_plan_values: [
        "1 year",
        "1-5 years",
        "5-10 years",
        "More than 10 years",
      ],
      area_repaired_values: [
        "Unfinished basement",
        "Finished Living Space",
        "Outdoor",
      ],
      other_bids_values: ["Company", "Comments"],
      timeline_fix_values: [
        "As soon as we can",
        "in next 90 days",
        "in 1 year",
      ],
      survey_info: {
        property_plan: "",
        area_repaired: "",
        other_bids: "",
        timeline_fix: "",
        other_comments: "",
      },
    };
  },
};
</script>

<style scoped></style>
